.search-position-data-delay {
  padding: var(--sdds-spacing-layout-16) var(--sdds-spacing-layout-16);
  height: 100%;
  display: flex;
    flex-direction: column;

  .search-wrapper {
    background-color: var(--sdds-grey-50);
    border-radius: 5px;
    display: flex;
   
    padding: var(--sdds-spacing-element-16);
    margin:var(--sdds-spacing-element-8);
    margin-bottom: var(--sdds-spacing-element-20);

    .space-between {
      justify-content: space-between;
    }

    .flex {
      display: flex;

      svg {
        color: var(--sdds-blue-500);
        width: 20px;
        margin-right: var(--sdds-spacing-layout-8);
        position: relative;
        top: -4px;
      }
    }    
  }
  .message-filters {   
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    .csd-number {
      margin-top: var(--sdds-spacing-element-4);
     margin-left: var(--sdds-spacing-element-12);
    }
    .spinner {
      display: inline-block;            
      height: 32px;      
    }
    .sdds-btn.sdds-btn-sm {
      height:var(--sdds-spacing-element-32);
      line-height: var(--sdds-spacing-element-8);     
    }
  } 
}

.map-container {
  display: flex;
  flex-direction: column;
  z-index: 999;

  .map-control {
    min-height: 64vh;
    min-width: 63vw;
    height: 500px;
    overflow: hidden;
  }

  .here-map-tooltip .H_ib_body {
    width: 40em;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_el {
    height: 40px;
  }

  .H_menu_title {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    background-color: #575B63;
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
    cursor: default;
  }

  .H_btn_no_shadow {
    box-shadow: none !important;
  }

  .H_btn.H_active {
    background-color: inherit !important;
    font-weight: bold;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_el {
    height: 40px;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_btn span {
    margin-left: 15px;
    font-size: 10px;
    line-height: 10px;
    display: block;
    font-weight: normal;
  }

  .H_center {
    // display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 100%;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_btn:before {
    content: '\2713\0020';
    font-weight: bold;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_btn:not(.H_active):before {
    color: #fff;
  }

  .H_menu_title.H_menu_title_top {
    border-radius: 5px 5px 0 0;
  }


  .H_ctl {
    .H_btn {
      margin-bottom: var(--sdds-spacing-layout-8);

      img {
        width: 40px;
        height: 40px
      }

      span {
        display: block;

        img {
          display: inline-block;
          vertical-align: middle;
          padding-right: var(--sdds-spacing-layout-8);
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .H_ctl .H_overlay .H_btn {
    margin-bottom: 0
  }

  .map-tooltip-redirect{
    height:16px;
    width:16px;
    margin-bottom:-2px;
    margin-right:4px;
    filter:invert(32%) sepia(21%) saturate(3045%) hue-rotate(185deg) brightness(90%) contrast(93%);
  }
  .map-tooltip-link{
    color: var(--sdds-blue-500);
    :visited{
      color: var(--sdds-blue-500);
    }
  }
}