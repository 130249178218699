.csd-details-wrapper {
  // background-color: var(--sdds-grey-50);
  flex: 100%;
  height:100%; 

  .csd-details-header {
    display: flex;
    position: sticky;
    top: 0;
    span {
      flex: 90%;
    }

    button {
      border: 0;
      background: none;
      padding-top: 5px;

      &:hover {
        background-color: var(--sdds-grey-300);
        cursor: pointer;
      }
    }

    svg {
      width: 20px;
    }
  }

  .sdds-inline-tabs {
    height:100%;    
    .sdds-inline-tabs-main {
      background-color: var(--sdds-grey-50) !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      height:calc(100% - 120px);
      overflow-y: scroll;
      /* Portrait and Landscape */
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        height: 90%;
      }
    }
  }

  .white-box {
    background: var(--tds-white);
    display: flex;
    flex-direction: column;
    padding-left: var(--sdds-spacing-layout-16);
    margin-right:var(--tds-spacing-element-20);

    >div {
      margin-bottom: var(--sdds-spacing-layout-16);
    }

    span {
      display: inline-flex;

      &:nth-child(odd) {
        min-width: 25%;
      }

      &:nth-child(even) {
        min-width: 50%;
      }
    }
  }

  .label {
    display: inline-block;
    border-radius: 3px;
    background-color: #e7e9ee;
    line-height: 20px;
    /* 142.857% */
    padding: 5px;

    &.red {
      color: var(--sdds-white);
      background-color: var(--sdds-negative);
    }

    &.green {
      color: var(--sdds-white);
      background-color: var(--sdds-positive);
    }
  }
}