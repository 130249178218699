.csd-system-subscriptions {
  height: calc(100vh - 20px);
  
  .sticky{
    position: sticky;
    top:0;
    z-index: 2;
  }
  .spin-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    background-blend-mode: lighten;
    z-index: 3;
    opacity: 1.0;

    .spinner {
      margin: 20% auto;
    }
  }

  .toast-div {
    position: absolute;
    right: 0;
    bottom: 40px;
    z-index: 100;
  }

  .scroll-container {
    
    height: 60%;
    overflow-y: scroll;
  
    .zero-left {
      margin-left: 0;
    }
  
    .selection-container {
      background-color: white;
      padding-bottom: 16px;
    }
  }

  .button-container{
    position:sticky;
    bottom:0;
    z-index:2;
    background-color: white;
    padding: 16px;
  }

  .tds-container {
    .tds-row {
      .subscription-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1px;
        margin: 0 0 10px;
        padding: 0;
        list-style: none;
        width: 100%;
        min-width: 0;

        li {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 20px;
          text-transform: capitalize;
          display: inline-block;
          width: calc(30%);

          @media only screen and (max-width: 920px) {
            width: calc(49%);
          }

          @media only screen and (max-width: 600px) {
            width: 100%;
          }
        }

        .sdds-form-input[type=checkbox] {
          vertical-align: bottom;
        }
      }
    }
  }

  .sdds-btn-fullbleed {
      width: 100%;
  }

  .sdds-textfield-input {
    min-width: 208px;
    max-width: 208px;
    height: 50px;
    position: relative;
    border: 1px solid var(--sdds-grey-600);
    border-bottom: 1px solid var(--sdds-grey-600);
    margin-top: 16px;
    &:hover {
      border-bottom-color: var(--sdds-textfield-border-bottom-hover);
    }

    @media only screen and (max-width: 768px) {
      float: none;
      top: 0;
    }
  }

  ::placeholder {
    color: var(--sdds-grey-600);
    opacity: 1;
  }

  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--sdds-grey-600);
  }
}