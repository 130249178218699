.detailsAndImpact {
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  height:calc(100vh - 326px);  
  width: 34%;
  padding: 0 var(--sdds-spacing-layout-24) 0 var(--sdds-spacing-layout-24);

  .csd-details-panel {
    margin: 0;
    margin-bottom: var(--sdds-spacing-layout-24);
    height:100%;
    .sdds-inline-tabs-main {
      height: max-content;
      overflow-y: visible;
    }
  }
}

.selected-system-details {
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  margin-right:var(--tds-spacing-element-20);
  height:calc(100vh - 326px);
  width: 34%;
  padding: 0 var(--sdds-spacing-layout-16) 0 var(--sdds-spacing-layout-16);

  tds-folder-tab{
    width:170px;
    button{
      width:170px;
    }
  }

  .panel-wrapper {
    display: flex;
    height:100%;

    .sdds-headline-05 {
      width: 100%;
      display: block;
    }

    .grid-panel {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height:100%;

      .sdds-table .sdds-table__row {
        display: table;
        width: 100%;
        cursor: pointer;
      }

      div.sdds-link {
        display: table-row;
        width: 100%;

        .sdds-table__row {
          display: table;
          width: 100%;
        }
      }
     .selected-row .sdds-table__row{
        background-color:var(--sdds-grey-300);
      }

      .cd-table-wrapper {
        width: auto;
        max-width: 100%;
        height:100%;

        .table-wrapper {
          overflow-y: auto;          
          border: 1px solid var(--sdds-grey-400);

          .sdds-table {
            .sdds-table__header {
              position: sticky;
              top: 0;
            }
          }
        }
      }

      td.sdds-table__body-cell.freeze {
        max-width: 192px;
        vertical-align: middle;
        a{
          text-decoration: none;
          color:var(--tds-blue-500);
          line-height: 20px;
          &:hover{
            color:var(--tds-blue-300);
          }
        }
      }

      .sdds-table__row .redirect {
        height: var(--tds-spacing-element-20);
        width: var(--tds-spacing-element-20);
        margin-bottom: -4px;
        margin-right: var(--tds-spacing-element-4);
      }
    }

    /* Portrait and Landscape */
    @media only screen and (min-width: 768px) and (max-width: 1525px) {
      flex-flow: column-reverse;
      display: flex;
      overflow-y: auto;
      max-height: calc(100vh - 180px);

      .grid-panel {
        .cd-table-wrapper {
          width: 100%;

          .table-wrapper {
            overflow-y: visible;
            max-height: max-content !important;
          }
        }
      }
    }
  }

  .grid-panel {
    padding-left: 0;

    .tab-container {
      background-color: var(--sdds-grey-50);
      height:calc(100% - 145px);
      .load-more-btn{
        height:36px;
        padding:var(--tds-spacing-element-4);
        border-radius: 0;
        width:100%;
      }
    }

    tds-folder-tabs {
      svg {
        margin: 0 4px -4px 0;
        width: 20px;
        height: 20px;
      }
    }

    
  }

  .cd-table {
    display: none;
    
  }

  .sdds-table {
    width: 100%;
  }

  .table-wrapper .sdds-table .sdds-table__header .sdds-table__row .sdds-table__header-cell {
    height: inherit;
    padding-left: var(--tds-spacing-element-16);
  }

  .disabledDiv {
    opacity: 0.5;
    pointer-events: none;
  }

  .morearrow {
    display: flex;
    justify-content: space-between;
  }
}