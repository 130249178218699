.definitions {
  padding: var(--sdds-spacing-layout-32) var(--sdds-spacing-layout-16);
  height: 100%;

  .wrapper {
    
    border-radius: 5px;
    display: flex;
    margin-bottom: var(--sdds-spacing-element-16);
    .space-between {
      justify-content: space-between;
    }

    .flex {
      display: flex;
      button {
        margin-left: var(--sdds-spacing-layout-16);
      }
    }
  }

  .details-view  {
    display: flex;
    margin-top:1vh;
    height: 100%;
    flex-direction: row;
    

    input::placeholder {
        font-family: inherit !important;
        font-size: 14px;
    }

    .createBtn {
        margin: 5rem 10rem;
        width: 25rem;
    }

    .table-title {
        background-color: var(--sdds-grey-400);
        color: var(--sdds-grey-958);
        height: 6vh;
        min-height: 15rem;
        width: 145rem;
        margin: 5rem 0 0 5rem;
        display: flex;
        padding-left: 3rem;
        align-items: center;

        .title {
            flex: 5;
        }

        button {
            flex: 1.5;
            margin: 2rem;
        }

        .search-icon{
            padding-right:2rem;
            cursor: pointer;
            flex: 3;
            display: flex;
            justify-content: flex-end;
        }
        .search-bar {
            flex: 2;
        }
        .close-search{
            cursor: pointer;
            height:10rem;
            border-bottom: 2px solid var(--sdds-grey-400);
            width:8rem;
            display:flex;
            align-items: center;
            background-color: var(--sdds-grey-50);
            border-radius: 0 4px 0 0;
            
         }
    }

    .table-headers {
        background-color: var(--sdds-grey-300);
        color: var(--sdds-grey-958);
        /* height: 4vh;
        min-height: 10rem; */
        width: 145rem;
       // margin: 0 0 0 5rem;
        display: flex;
        padding: 3rem;
        align-items: center;

        .name-column {
            width: 110rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        .id-column {
          width: 30rem;
      }
    }

    .details-view-table-container {
        width: 145rem;
        margin: 0 5rem 0 0;
        //height: 70vh;
        //min-height: 155rem;
        height:calc(100vh - 280px);  
        border: 1px solid var(--sdds-grey-200);
        overflow-y: scroll;
        overflow-x: hidden;
        

        .spinner{
            margin-top:50%;
        }

        .details-view-cell{
          height:auto;
          width:140rem;
          border-bottom: 1px solid var(--sdds-grey-200);
          display: flex;
          align-items: center;
          padding:3rem;
          cursor: pointer;
          color: var(--sdds-blue-500);
      
          .id-column{
              width:30rem;
          }
      
          .name-column{
              width:126rem;
              display: flex;
              justify-content: flex-start;
              line-height: 5rem;
          }
          &:hover{
              background-color: var(--sdds-grey-100);
          }
      }
	  .selected {
		background-color: var(--sdds-grey-100);
	  }
    } 
    .info-icon {
        margin: 25% 5%;
        display: flex;
        span {
            vertical-align: 2px;
            padding-left: 10px;
        }
        svg {
            width: 20px;
            height: 20px;
            color: var(--sdds-blue-500);

            &:hover {
                +.custom-tooltip {
                    display: block;
                    position: absolute;
                    opacity: 1;
                }
            }
        }
    }  
    .disclaimer{
       display: flex;
       justify-content: center;
       margin-top:10%;
           
        svg {
            margin:0 4px;
            width: 20px;
            height: 20px;
            color: var(--sdds-blue-500);


        }
    
}
}
}
