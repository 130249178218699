
.container {
  display: flex;
  p{
    flex:1;
  }
  
}

.header {
  width: 256px;
  color: var(--Grey-958, #0D0F13);
  /* Headline/05 */
  font-family: "Scania Office";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.32px;
}
.docs-user-modal{
  .modalBodyOveride{
    min-height: fit-content;
  }
}
.description { 
  margin-bottom: var(--tds-spacing-element-24);
  flex-shrink: 0;
  color: #000;  
}

.column {
  flex: .3; 
  // Your column styles go here  
  justify-content: space-between;
  font-weight: bold;
}

.sdds-textfield-input {
  min-width: 208px;
  
  position: relative;
  top: -16px;
  border: 1px solid var(--sdds-grey-600);
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid var(--Grey-600, #868FA2);
  background: var(--Grey-50, #F9FAFB);

  display: flex;
  height: 40px;
  padding: 18px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  &:hover {
    border-bottom-color: var(--sdds-textfield-border-bottom-hover);
  }

  @media only screen and (max-width: 768px) {
    float: none;
    top: 0;
  }
}

.toast-div {
  position: absolute;
  right: 0;
  bottom: 40px;
  z-index: 100;
}