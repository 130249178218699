#confirmationDialogContainer { 
   
    @media only screen and (max-width: 950px) and (min-width: 400px) {
        .sdds-modal-md {
            width: 80%;
            
        }
    }

    @media only screen and (max-width: 1500px) and (min-width: 950px) {
        .sdds-modal-md {
            width: 40%;
            
        }
    }
    @media only screen and (min-width: 1500px) {
        .sdds-modal-md {
            width: 40% !important;
            max-width:100rem !important;
            
        }
    }

    .modalBodyOveride{
        min-height:30rem !important;
    }


}