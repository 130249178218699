::-webkit-scrollbar
{
  width:20px;   
  background-color: white;
  margin-right:2px !important;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px; 
  border: 7.5px solid white;
  background-color: #CDD1DB;  
  height:50px;
} 
.App{
  max-height: 100vh;
}
body{
  max-height: 100vh;
  overflow: hidden;
}
.custom-tooltip {
  display: none;
  word-wrap: break-word;
  white-space: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--sdds-white);
  background-color: var(--sdds-grey-800);
  border-radius: 1rem;
  padding: 2rem;
  max-width: 48rem;
  z-index: 20000;
  opacity: 0;
  transition: opacity 200ms ease-in 0s, visibility 200ms ease-in 0s;
}