* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.system-overview {
  background: #f9fafb;
  height: calc(100vh - 326px);
  overflow-y: scroll;
  min-width: 500px;
  width: 33%;
  margin-right: var(--tds-spacing-element-20);
  padding: var(--tds-spacing-element-12) 0 0 var(--tds-spacing-element-20);
  .sdds-headline-05 {
    width: 100%;
    display: block;
  } 
}

::-webkit-scrollbar {
  background-color: var(--tds-grey-50);
}

::-webkit-scrollbar-thumb {
  border: 7.5px solid var(--tds-grey-50);
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  width: 100%;
  border-radius: 5px;
}

.svg {
  color: var(--sdds-blue-500);
  width: 20px;
  height: 20px;
  margin-right: var(--sdds-spacing-layout-8);
  position: relative;
}

.background {
  background-color: rgb(237, 239, 243);
}
