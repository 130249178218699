.definition-form {
    /* height: 80vh;
    min-height: 180rem; */
    height:calc(100vh - 180px);  
    overflow-y: scroll;
    flex: 1;
    background-color: var(--sdds-grey-50);
    display: flex;
    padding: 5rem;
    flex-direction: column;
    position: relative;

    .form-top-bar {
        display: flex;


        .secondary-action-buttons {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }

        .flex {
            display: flex;

            button {
                margin-left: var(--sdds-spacing-layout-16);
            }
        }
    }

    .space-between {
        justify-content: space-between;
    }

    .form-bottom-bar {
        margin-top: 10rem;
        display: flex;
        .flex {
            display: flex;

            button {
                margin-left: var(--sdds-spacing-layout-16);
            }
        }
    }

    .form-fields {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .sdds-textfield-input {
            max-width: inherit;
            top: -8px;
        }
        .message, .actions {
            margin-top: var(--sdds-spacing-layout-32);
        }
        tds-dropdown, sdds-dropdown, select {
            width: 100%
        }

        .form-field {
            display: flex;
            flex-direction: row;
            margin-top: 6rem;

            .sdds-checkbox-item {
                margin-top: 3rem;
                margin-bottom: 5rem;
            }

            .form-label {
                min-width: 250px;
                color: var(--sdds-grey-700);
                display: flex;
                justify-content: flex-end;
                line-height: 24px;

                .info-icon {
                    margin: 0 4px;
                    svg {
                        width: 20px;
                        height: 20px;
                        color: var(--sdds-blue-500);

                        &:hover {
                            +.custom-tooltip {
                                display: block;
                                position: absolute;
                                opacity: 1;
                            }
                        }
                    }
                }

            }
            
            .form-label,
            .seperator {
                margin-top: 3rem;
            }

            .form-value {
                margin-left: 10rem;
                width: 100%;

                .view-state {
                    display: flex;
                    line-height: 48px;

                    tds-text-field {
                        width: 100%;
                        .text-field-icon__readonly.sc-tds-text-field {
                            top: inherit
                        }
                    }
                    .functionLineHeight {
                        line-height: normal;
                        padding-top: 15px;
                    }
                }
                .error {
                    color: #FF2340;
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                        vertical-align: -4px;
                        color: #FF2340;

                        &:hover {
                            +.custom-tooltip {
                                display: block;
                                position: absolute;
                                opacity: 1;
                            }
                        }
                    }
                }
            }



        }
    }
    .loader {
        border-radius: 5px;
        background: var(--sdds-white);
        opacity: 0.8;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        .spinner {
          position: relative;
          z-index: 100;
          top: 50%; 
          bottom: 50%;
          flex-direction: column;
          color: var(--sdds-grey-958);
          font-weight: bold;
          .spinner {
            padding-top: var(--sdds-spacing-layout-16);
          }
        }
    }
    
}