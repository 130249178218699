#systems-modal {
    &.customModal {
        padding: 16px;
        max-height: inherit !important;
        overflow-y: inherit !important;
        .sdds-modal-body>* {
            padding-right: 2rem;
        }

        .sdds-textfield-input {
            min-width: 208px;
            max-width: 208px;
            height: 50px;
            float: right;
            position: relative;
            top: -16px;
            border: 1px solid var(--sdds-grey-600);

            &:hover {
                border-bottom-color: var(--sdds-textfield-border-bottom-hover);
            }

            @media only screen and (max-width: 768px) {
                float: none;
                top: 0;
            }
        }
        .tds-accordion-item-action {
            padding-left: 16px;
        }
        .tds-accordion-item-action button {
            margin: var(--sdds-spacing-element-8);
        }
       
        .sdds-btn.sdds-btn-xs {
            padding: var(--sdds-spacing-element-8);
            height: 8rem;
        }
        tds-accordion ul.accordion-list {
            list-style-type: none !important;
            padding: 0;
            margin: 0;
            li {
                width: auto
            }
        }
        tds-accordion ul.checkbox-list {
            padding: var(--sdds-spacing-element-8);
            margin:0 0 0 var(--sdds-spacing-element-16);
            width
            li {
                min-width: 20%;
                list-style: none;
            }
        }
        input.sdds-form-input {
            vertical-align: -1px;
            margin-right: 6px;
        }
        .checkbox-container {
            display: flex;
            flex-wrap: wrap;
            ul.checkbox-list {
                padding: var(--sdds-spacing-element-8);
                margin:0 0 0 var(--sdds-spacing-element-16);
                width: 100%;
                li {
                    width: 20%;
                    display: inline-block;
                }
            }
          }
          .checkbox-item {
            width: 20%; 
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-bottom: 10px; 
          }
          .checkbox-item input[type="checkbox"] {
            margin-right: 5px; 
          }
        ::placeholder {
            color: var(--sdds-grey-600);
            opacity: 1;
        }

        ::-ms-input-placeholder {
            /* Edge 12-18 */
            color: var(--sdds-grey-600);
        }
        .spiner-section {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.1);
            background-blend-mode: lighten;
            z-index: 3;
            opacity: 1.0;
            .spinner {
              margin: 20% auto;
            }
          }
    }
}