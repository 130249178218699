.searchBar{
    display: flex;
    margin: var(--sdds-spacing-element-16) var(--sdds-spacing-element-32)  0 0;
    input{
        border-radius: 4px 4px 0 0;
        border: 1px solid white;
        border-bottom: 1px solid;
        padding: 10px;
        line-height: 24px;
        width: 100%;
    }    
 }
