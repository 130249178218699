.accordion-wrapper {
   margin: 0 0 var(--sdds-spacing-layout-8) 0;
   svg {
      width: 16px;
      height: 16px
   }

   .rc-accordion-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      background-color: var(--sdds-grey-100);
      transition: 0.3s;
      border-top: 2px solid transparent;
      height:var(--tds-layout-element-48);
      padding:var(--tds-spacing-element-16);
   }

   .rc-accordion-toggle.active {
      background-color: var(--sdds-white);
      border-top: 2px solid var(--tds-folder-tab-border-selected);
      color: var(--sdds-grey-958);
   }

   .rc-accordion-toggle.active .rc-accordion-icon {
      transform: rotate(180deg);
      color: #fff;
   }

   .rc-accordion-card {
      margin-bottom: 10px;
      overflow: hidden;
   }

   .rc-accordion-card:last-child {
      margin-bottom: 0;
   }

   .rc-accordion-header{
      height:var(--tds-spacing-layout-48);
   }
   .rc-accordion-title {      
      position: relative;
      color: var(--sdds-grey-958);
      transition: 0.3s;
      font-family: 'Scania Sans Semi Condensed';
      
      line-height: normal;
      width: 140px;
   }

   .rc-accordion-toggle.active .rc-accordion-title {
      color: var(--sdds-grey-958);
   }

   .rc-accordion-icon {
      position: relative;
      top: 2px;
      color: var(--sdds-grey-958);
      transition: 0.35s;
      font-size: 12px;
   }

   .rc-accordion-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 0;
      border-top: 1px solid var(--sdds-grey-100);
      overflow-y: auto;
     

      .rc-accordion-row {
         display: flex;
         align-items: center;
         justify-content: space-between;
         background-color: var(--sdds-white);
         transition: 0.3s;
         padding: var(--tds-spacing-element-16);
         height: var(--tds-spacing-element-20);
         cursor: pointer;
         
         &:hover{
            background-color: var(--sdds-grey-200);
         }
      }
   
      .selected-row{
         background-color:var(--sdds-grey-300);
      }
      
      .greyed-out{
         color: var(--tds-grey-500);
         svg{
            filter: invert(81%) sepia(11%) saturate(275%) hue-rotate(180deg) brightness(90%) contrast(88%);
            color: var(--tds-grey-958);
         }
      }
   }

   .rc-accordion-right {
      display: flex;          

      span {
         margin: 0 10px;

         svg {
            vertical-align: -2px;
            min-width: inherit;
         }

         span {
            margin-left: 5px;
            margin-right: 0;
            min-width: 79px;
         }
      }
   }
   .total{
      font-weight: bold;      
      .warning-svg-icon{
         color:black;
      }
   }

   .rc-collapse {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: height 0.35s ease;      
   }

   .rc-collapse.show {
      height: auto;
   }
}