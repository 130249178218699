#root {
  height: 100%;
}
.dashboard {
  padding: var(--sdds-spacing-layout-16) var(--sdds-spacing-layout-16);
  height: 100%;


  .search-wrapper {
    background-color: var(--sdds-grey-50);
    border-radius: 5px;
    display: flex;
    padding-top: var(--sdds-spacing-element-8);

    .space-between {
      justify-content: space-between;
    }

    .flex {
      display: flex;

      svg {
        color: var(--sdds-blue-500);
        width: 20px;
        margin-right: var(--sdds-spacing-layout-8);
        position: relative;
        top: -12px;
      }
    }

    .refresh {
      padding: 49px 0 0 10px
    }
    .date-search-container {
      min-height: 92px
    }
  }

  .upper-bar-flex {
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}
.details-view {
  display: flex;
  height: 100%;
  flex-direction: row;
}
.sdds-headline-05 {
  padding: var(--sdds-spacing-layout-8) 0
}
}
