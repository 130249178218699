.records-wrapper {
  background-color: var(--sdds-grey-50);
  flex: 100%;
  margin-left: var(--sdds-spacing-layout-8);
  min-width: 380px;

  .records-header {
    display: flex;

    span {
      flex: 90%;
    }

    button {
      border: 0;
      background: none;
      padding-top: 5px;
      &:hover {
        background-color: var(--sdds-grey-300);
        cursor: pointer;
      }
    }

    svg {
      width: 20px;
    }
  }

  .records-panel {
    padding: var(--sdds-spacing-layout-16);
    overflow-y: visible;
    max-height: calc(72vh - var(--sdds-spacing-layout-32));
    width: 100%;
    span {
      display: block;
      width: 100%;
      padding: var(--sdds-spacing-layout-8) 0;
      span {
        display: inline;
        width: auto;
      }
    }
  }

  .sdds-inline-tabs {
    .sdds-inline-tabs-main {
      background-color: var(--sdds-grey-50) !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      /* Portrait and Landscape */
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        height: 100%;
      }
    }
  }

  .white-box {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: var(--sdds-spacing-layout-16) var(--sdds-spacing-layout-16) 0
      var(--sdds-spacing-layout-16);

    > div {
      margin-bottom: var(--sdds-spacing-layout-16);
    }

    span {
      display: inline-flex;

      &:nth-child(odd) {
        min-width: 25%;
      }

      &:nth-child(even) {
        min-width: 50%;
      }
    }
  }

  .label {
    display: inline-block;
    border-radius: 3px;
    background-color: #e7e9ee;
    line-height: 20px; /* 142.857% */
    padding: 5px;
    &.red {
      color: var(--sdds-white);
      background-color: var(--sdds-negative);
    }
    &.green {
      color: var(--sdds-white);
      background-color: var(--sdds-positive);
    }
  }
}
